import React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import PlainHeader from "components/ui/base/PlainHeader";
import { useTranslation } from "react-i18next";
import StartupSteps from "components/ui/extended/StartupSteps";
import Customers from "components/ui/extended/Customers";
import StartupBenefits from "components/ui/extended/StartupBenefits";
import FeatureGrid from "components/ui/base/FeatureGrid";
import StartupGoodFit from "components/ui/extended/StartupGoodFit";
import TestimonialWithImageFrancesca from "components/ui/extended/TestimonialWithImageFrancesca";
import StartupForm from "components/ui/extended/StartupForm";

export interface StartupsProps {
  location: Location;
}

export default ({ location }: StartupsProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.startups.seo.title")}
        description={t("pages.startups.seo.description")}
        image={{
          relativePath: "meta/1-million-dollar-found-for-startups.jpg",
          alt: "Customerly for Startups",
        }}
      />
      <PlainHeader
        title={t("pages.startups.title")}
        description={t("pages.startups.description")}
        titleMaxWidth={1000}
        descriptionMaxWidth={700}
        ctaButton={{
          show: true,
          text: t("pages.startups.cta"),
          icon: "arrow-right",
          path: "#startupForm",
        }}
      />

      <StartupBenefits />

      <StartupGoodFit />

      <FeatureGrid
        alternativeColors
        title={t("pages.startups.featureGrid.title")}
        sections={[
          {
            title: t("pages.startups.featureGrid.liveChat.title"),
            description: t("pages.startups.featureGrid.liveChat.description"),
            links: [
              {
                text: t("pages.startups.featureGrid.liveChat.link"),
                path: "/features/live-chat/",
              },
            ],
          },
          {
            title: t("pages.startups.featureGrid.marketingAutomation.title"),
            description: t(
              "pages.startups.featureGrid.marketingAutomation.description"
            ),
            links: [
              {
                text: t("pages.startups.featureGrid.marketingAutomation.link"),
                path: "/features/marketing-funnel/",
              },
            ],
          },
          {
            title: t("pages.startups.featureGrid.helpCenter.title"),
            description: t("pages.startups.featureGrid.helpCenter.description"),
            links: [
              {
                text: t("pages.startups.featureGrid.helpCenter.link"),
                path: "/features/help-center/",
              },
            ],
          },
          {
            title: t("pages.startups.featureGrid.emailMarketing.title"),
            description: t(
              "pages.startups.featureGrid.emailMarketing.description"
            ),
            links: [
              {
                text: t("pages.startups.featureGrid.emailMarketing.link"),
                path: "/features/email-marketing/",
              },
            ],
          },
          {
            title: t("pages.startups.featureGrid.npsSurveys.title"),
            description: t("pages.startups.featureGrid.npsSurveys.description"),
            links: [
              {
                text: t("pages.startups.featureGrid.npsSurveys.link"),
                path: "/features/net-promoter-score/",
              },
            ],
          },
          {
            title: t("pages.startups.featureGrid.inAppSurveys.title"),
            description: t(
              "pages.startups.featureGrid.inAppSurveys.description"
            ),
            links: [
              {
                text: t("pages.startups.featureGrid.inAppSurveys.link"),
                path: "/features/surveys/",
              },
            ],
          },
        ]}
      />

      <StartupSteps />

      <TestimonialWithImageFrancesca />

      <StartupForm />

      <Customers />
    </GlobalWrapper>
  );
};
